@import url("https://fonts.googleapis.com/css?family=Roboto");

.home-page {
  font-family: "Roboto";
  font-style: normal;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  padding: 25px 60px 20px 40px;
  .nav-items {
    .item {
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.2px;
      margin-right: 35px;
      cursor: pointer;
    }
    .contact-btn {
      margin-left: 20px;
      border: 2px solid #001529;
      border-radius: 4px;
      &:hover {
        background-color: #001529;
        color: #fff;
      }
    }
  }

  .page-text-container {
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    p {
      font-size: 45px;
      line-height: 65px;
      text-align: right;
      letter-spacing: 0.2px;

      color: #263b5e;
    }
    .plain-text {
      font-weight: 300;
      font-size: 18px;
      line-height: 32px;
      max-width: 600px;
      text-align: right;
      letter-spacing: 0.2px;
    }
  }
}
