@import "variables";
@import "base";

:root {
  --blue: #00cfe8;
  --indigo: #6610f2;
  --purple: #7367f0;
  --pink: #e83e8c;
  --red: #ea5455;
  --orange: #ff9f43;
  --yellow: #ffc107;
  --green: #28c76f;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #b8c2cc;
  --gray-dark: #1e1e1e;
  --primary: #001529;
  --secondary: #82868b;
  --success: #28c76f;
  --info: #00cfe8;
  --warning: #ff9f43;
  --danger: #ea5455;
  --light: #f6f6f6;
  --dark: #4b4b4b;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
}

html {
  font-size: 14px;
  letter-spacing: 0.01rem;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.--main-container {
  .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
    color: #6e6b7b;
  }
  .trigger:hover {
    color: $primary;
  }

  .ant-layout {
    background-color: var(--white);
  }
}
.app-container {
  grid-column: 2;
  height: calc(100vh - 64px);
  overflow: hidden;
  overflow-y: auto;
}

.content-body {
  height: 100%;
  padding: var(--spacing-xxl);
  overflow-y: auto;
}

.page-header {
  .page-heading {
    font-size: 1.8rem;
    font-weight: 600;
    margin: 0;
  }
  .page-heading-small {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
  }

  margin-bottom: var(--spacing-xxl);
}

.--avatar {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 35px;
  height: 35px;
  border-radius: 4px;
  font-size: 15px;
  &.small {
    width: 25px;
    height: 25px;
    font-size: 13px;
  }
  &.green {
    background-color: rgba(40, 199, 111, 0.12);
    color: var(--green);
  }
  &.orange {
    background-color: rgba(255, 159, 67, 0.12);
    color: var(--warning);
  }
  &.purple {
    background-color: rgba(115, 103, 240, 0.12);
    color: var(--purple);
  }
  &.red {
    background-color: rgba(234, 84, 85, 0.12);
    color: var(--red);
  }
  &.cyan {
    background-color: rgba(0, 207, 232, 0.12);
    color: var(--cyan);
  }
  &.pink {
    background-color: rgba(247, 138, 188, 0.12);
    color: var(--pink);
  }
  &.yellow {
    background-color: rgba(255, 247, 0, 0.13);
    color: var(--yellow);
  }
  &.circle {
    border-radius: 50%;
  }
}

.page-body {
  margin: var(--spacing-xxl) 0;
}
